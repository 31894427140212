var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pdf", {
        attrs: { src: _vm.pdfUrl, page: _vm.curPage },
        on: { "num-pages": _vm.getPageNum },
      }),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button-group",
            { staticClass: "page" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-arrow-left",
                    disabled: _vm.curPage === 1,
                  },
                  on: { click: _vm.prevPage },
                },
                [_vm._v("上一页 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.curPage === _vm.totalPage },
                  on: { click: _vm.nextPage },
                },
                [
                  _vm._v("下一页 "),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-button-group",
            { staticClass: "download" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.download },
                },
                [_vm._v("下载")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", icon: "el-icon-close" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }