<template>
  <div>
    <pdf
      :src="pdfUrl"
      :page="curPage"
      @num-pages="getPageNum"
    ></pdf>
    <!-- <vue-pdf-app :pdf="pdfUrl"></vue-pdf-app> -->
    <div class="footer">
      <el-button-group class="page">
        <el-button
          @click="prevPage"
          icon="el-icon-arrow-left"
          :disabled="curPage === 1"
        >上一页
        </el-button>
        <el-button
          @click="nextPage"
          :disabled="curPage === totalPage"
        >下一页 <i class="el-icon-arrow-right"></i> </el-button>
      </el-button-group>
      <el-button-group class="download">
        <el-button type="primary" icon="el-icon-download" @click="download">下载</el-button>
        <el-button type="danger" icon="el-icon-close" @click="closeModal">关闭</el-button>
      </el-button-group>
    </div>
  </div>
</template>
<script>
// import VuePdfApp from 'vue-pdf-app';
import pdf from 'vue-pdf';

import Form from '../../../../../../components/form';

export default {
  extends: Form,
  components: {
    // VuePdfApp,
    pdf,
  },
  data() {
    return {
      buttons: {
        // submit: false,
      },
      pdfUrl: '',
      curPage: 1, // 当前页
      totalPage: 1, // 总页数
    };
  },
  methods: {
    getPageNum(val) {
      this.totalPage = val;
    },
    prevPage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
      }
    },
    nextPage() {
      if (this.curPage < this.totalPage) {
        this.curPage += 1;
      }
    },
    // closeModal() {
    //   this.$emit('onClose');
    // },
    download() {
      const a = document.createElement('a');
      const fileName = `${this.formConfig.row.activityCode}.pdf`;
      a.setAttribute('href', this.pdfUrl);
      a.setAttribute('download', fileName);
      a.click();
    },
  },
  created() {
    this.pdfUrl = this.formConfig.url;
  },
};
</script>
<style lang="less" scoped>
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    width: 100%;
    height: 73px;
    z-index: 999;
    border-top: 1px solid #e4e4e4;
    justify-content: flex-end;
    .page {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .download{
      position: absolute;
      right: 50px;
      .el-button {
        margin-left: 8px;
      }
    }
  }
</style>
